import { TYPES } from '../types';
import { http } from '@/http'
import { _buildQuery } from '@/utils/Index';

const state = {
    supports: [],
    meta: {},
    details: [],
    next_url: null,
    actvieRef: null
}
export default {
    namespaced: true,
    state: state,

    mutations: {
        [TYPES.mutations.SUPPORTS_DATA](state, { data }) {
            state.supports = data.data;
            state.meta = data.meta
        },

        [TYPES.mutations.DELETE_SUPPORT](state, { support }) {
            const index = state.supports.indexOf(support);
            state.supports.splice(index, 1);
        },

        [TYPES.mutations.DETAILS_SUPPORT](state, { support, ref }) {
            state.details = support;
            //state.next_url = support.next_page_url;
            state.actvieRef = ref;
        },

        [TYPES.mutations.PUSH_MESSAGE](state, { support }) {
            state.details.push(support);
        },
    },
    getters: {
        [TYPES.getters.GET_SUPPORTS](state) {
            return state.supports;
        },
        [TYPES.getters.GET_SUPPORTS_META](state) {
            return state.meta;
        },
        [TYPES.getters.GET_SUPPORT](state) {
            return state.details;
        },
    },
    actions: {
        [TYPES.actions.GET_SUPPORTS]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get(`tickets` + _buildQuery(payload)).then((res) => {
                    commit(TYPES.mutations.SUPPORTS_DATA, { data: res.data });
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.DELETE_SUPPORT]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('tickets/delete', { id: payload.id }).then((res) => {
                    commit(TYPES.mutations.DELETE_SUPPORT, { support: payload });
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.ADD_SUPPORT]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('tickets/create', payload).then((res) => {
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.SEND_SUPPORT]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('tickets/response', payload).then((res) => {
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.BEGIN_TREAT]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('tickets/begin', { id: payload.id }).then((res) => {
                    resolve(true)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.MARK_AS_TREAT]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('tickets/treat', { id: payload.id }).then((res) => {
                    resolve(true)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.DETAILS_SUPPORT]({ commit, state }, payload) {
            return new Promise((resolve, reject) => {
                if (state.actvieRef == payload.ref) {
                    resolve(true);
                } else {
                    http.setHeader();
                    http.request().get('tickets/details/' + payload.ref).then((res) => {
                        commit(TYPES.mutations.DETAILS_SUPPORT, { support: res.data, ref: payload.ref });
                        resolve(true)
                    }).catch((error) => {
                        reject(error);
                    })
                }
            })
        },
    }
}
