<template>
  <transition name="slide-fade" mode="out-in">
    <div class="mcp-modal-overlay" v-if="show">
      <div class="mcp-modal">
        <div class="mcp-modal-background"></div>
        <div class="mcp-modal-content">
          <div class="mcp-close">
            <span class="mcp-btn-close" @click="closeModal" aria-hidden="true"
              >&times;</span
            >
          </div>
          <div class="mcp-box">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "mcpModalCenter",
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.mcp-modal {
  position: absolute;
  height: 100%;
  width: 400px;
  z-index: 10;
  background-color: #fff;
}

.mcp-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #646161da;
  z-index: 5;
}
.mcp-modal-background {
  background-color: rgba(10, 10, 10, 0.86);
}
.mcp-modal-content {
  width: 400px;
}
.mcp-box {
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
}

.mcp-close {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  font-size: 25px;
}
.mcp-btn-close {
  cursor: pointer;
}
.slide-fade-enter-active {
  //transition: all 0.3s ease;
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
// .slide-fade-leave-active {
//   //transition: all 0.3s ease-out;
//   -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
//     both;
//   animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
// }
.slide-fade-enter,
.slide-fade-leave-to {
  //transform: translateX(5px);
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>