<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { EventBus } from "@/utils/EventBus";
import { TYPES } from "@/store/types";
export default {
  data: () => ({
    gruad: ["login", "singup", "reset", "verification", "notfound"],
    is_mcp: false,
  }),
  watch: {
    $route(to) {
      if (!this.gruad.includes(to.name)) {
        if (this.is_mcp) this.getCurrent();
      }
    },
  },
  created() {
    this.is_mcp = this.$isMcp();
  },
  methods: {
    getCurrent() {
      this.$store
        .dispatch(`${TYPES.modules.USER + "/" + TYPES.actions.INFO_USER}`)
        .then((user) => {
          EventBus.$emit("auth", user);
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss">
</style>
