import Vue from 'vue';
import Vuex from 'vuex';
import User from './modules/auth';
import Customers from './modules/customers';
import Banks from './modules/banks';
import Services from './modules/services';
import Utils from './modules/utils';
import Subscriptions from './modules/subscriptions';
import Refunds from './modules/refunds';
import Supports from './modules/supports';
import Transactions from './modules/transactions';
import Dashboard from './modules/dashboard';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    User, Customers, Banks, Services, Utils, Subscriptions, Refunds,
    Supports, Transactions, Dashboard
  }
})
