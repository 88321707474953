import Vue from 'vue'
import VueRouter from 'vue-router'
import { isAuthenticate, isMcp, mcpProfile } from '@/utils/Auth'
import NotFound from '../views/notFound.vue';

const adminRoute = [
  'home', 'customers', 'refunds', 'operateurs', 'operateurs_create', 'operateurs_edit', 'services',
  'services_create', 'services_edit', 'souscriptions', 'support', 'profile', 'transactions', 'users'
];
const operatorRoute = ['transactions', 'profile'];
const clientRoute = ['home', 'services', 'support', 'profile', 'transactions'];

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  if (isAuthenticate()) {
    const profile = mcpProfile();
    if (to.name == 'login' || to.name == 'singup' || to.name == 'reset') {
      next('/')
    }else{
      switch(profile){
        case 0:
          if (clientRoute.includes(to.name)){
            next();
            return;
          }else { next('/404'); return }
          break;
        case 1:
          if (adminRoute.includes(to.name)){
            next();
            return;
          }else { next('/404'); return; }
          break; 
        case 2:
          if (operatorRoute.includes(to.name)){
            next();
            return;
          }else { next('/404'); return }
          break; 
      }
    }
  }
  next('/signin');
}

const guest = (to, form, next) => {
  if (isAuthenticate()) {
    if (to.name == 'login' || to.name == 'singup' || to.name == 'reset' || to.name == 'verification') {
      next('/')
    }
  } else {
    next();
    return;
  }
}


const routes = [
  { path: "/404", component: NotFound, name: 'notfound' },
  { path: "*", redirect: "/404" },
  {
    path: '',
    component: () => import('../views/Layout.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/customers',
        name: 'customers',
        component: () => import('../views/customers/index.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/operateurs',
        name: 'operateurs',
        component: () => import('../views/operateurs/index.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/operateurs/create',
        name: 'operateurs_create',
        component: () => import('../views/operateurs/add.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/operateurs/edit/:id',
        name: 'operateurs_edit',
        component: () => import('../views/operateurs/edit.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/services',
        name: 'services',
        component: () => import('../views/services/index.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/services/create',
        name: 'services_create',
        component: () => import('../views/services/add.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/services/edit/:id',
        name: 'services_edit',
        component: () => import('../views/services/edit.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/services/souscriptions.:id/:service',
        name: 'souscriptions',
        component: () => import('../views/souscriptions/index.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/refunds',
        name: 'refunds',
        component: () => import('../views/refunds/index.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/request-support',
        name: 'support',
        component: () => import('../views/support/index.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/auth/profile',
        name: 'profile',
        component: () => import('../views/users/profile.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/transactions',
        name: 'transactions',
        component: () => import('../views/transactions/index.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/users',
        name: 'users',
        component: () => import('../views/users/index.vue'),
        beforeEnter: ifAuthenticated,
      },
      // {
      //   path: '/mcp-direct',
      //   name: 'mcpdirect',
      //   component: () => import('../views/mcpdirect/index.vue'),
      //   beforeEnter: ifAuthenticated,
      // },
    ]
  },
  {
    path: '/auth/verification/:token',
    name: 'verification',
    component: () => import('../views/auth/verification.vue'),
    beforeEnter: guest,
  },
  {
    path: '/signin',
    name: 'login',
    component: () => import('../views/auth/login.vue'),
    beforeEnter: guest,
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('../views/auth/reset.vue'),
    beforeEnter: guest,
  },
  {
    path: '/singup',
    name: 'singup',
    component: () => import('../views/auth/register.vue'),
    beforeEnter: guest,
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: "active",
  routes
})

router.beforeEach((to, from, next) => {
  const notAllow = ['customers', 'operateurs', 'operateurs_create', 'operateurs_edit', 'services_create', 'services_edit', 'souscriptions', 'users'];
  if (isMcp() == true) {
    if (notAllow.includes(to.name)) {
      next('/404');
    }
    return next();
  }
  return next();
});
export default router
