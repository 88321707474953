<template>
  <div class="tt">
    <div class="wrapper">
      <h1>404 - Page introuvable!</h1>
      <p>La page que vous demandez est introuvable.</p>
      <br />
      <div class="buttons"><router-link :to="[0,1].includes(profile) ? '/' : '/transactions'">Retour</router-link><br /></div>
    </div>
  </div>
</template>

<script>
import { mcpProfile } from '../utils/Auth'
export default {
  data: () => ({
    profile: ''
  }),

  mounted(){
    this.profile = mcpProfile();
  }
};
</script>

<style lang="scss" scoped>
.tt {
  display: flex;
  align-items: center;
  background: #f6f6f9;
  min-width: 275px;
  height: 100vh;
  margin: 0 10vw;
  overflow: hidden;
  color: black;
}
.wrapper {
  flex-grow: 2;
  width: 40vw;
  max-width: 500px;
  margin: 0 auto;
}

h1 {
  margin: 0;
  font-size: 3em;
  font-weight: 100;
}

p {
  width: 95%;
  font-size: 1.5em;
  line-height: 1.4;
}

.buttons {
  padding-top: 10px;
  white-space: nowrap;
  display: inline-block;
}

span {
  display: block;
  text-transform: uppercase;
  color: black;
  letter-spacing: 1.5px;
  text-align: center;
}
a {
  display: inline-block;
  padding: 0.8em 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  border: 3px solid black;
  color: black;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.2em;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}

@media (max-width: 600px) {
  body {
    margin: 0 5vw;
  }
}
</style>