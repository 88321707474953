import { TYPES } from '../types';
import { http } from '@/http'
import { _buildQuery } from '@/utils/Index';

const state = {
    banks: [],
    meta: {},
    bank: {}
}
export default {
    namespaced: true,
    state: state,

    mutations: {
        [TYPES.mutations.BANKS_DATA](state, { data }) {
            state.banks = data.data;
            state.meta = data.meta
        },

        [TYPES.mutations.DELETE_BANK](state, { bank }) {
            const index = state.banks.indexOf(bank);
            state.banks.splice(index, 1);
        },

        [TYPES.mutations.DETAILS_BANK](state, { bank }) {
            state.bank = bank;
        },
    },
    getters: {
        [TYPES.getters.GET_BANKS](state) {
            return state.banks;
        },
        [TYPES.getters.GET_BANKS_META](state) {
            return state.meta;
        },
        [TYPES.getters.GET_BANK](state) {
            return state.bank;
        },
    },
    actions: {
        [TYPES.actions.GET_BANKS]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get(`banks` + _buildQuery(payload)).then((res) => {
                    commit(TYPES.mutations.BANKS_DATA, { data: res.data });
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.DELETE_BANK]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('banks/delete', { id: payload.id }).then((res) => {
                    commit(TYPES.mutations.DELETE_BANK, { bank: payload });
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.ADD_BANK]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('banks/create', payload).then((res) => {
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.UPDATE_BANK]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('banks/update', payload).then((res) => {
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.DETAILS_BANK]({ commit, state }, payload) {
            return new Promise((resolve, reject) => {
                const item = state.banks.filter(item => item.id === payload);
                if (item && item[0]) {
                    commit(TYPES.mutations.DETAILS_BANK, { bank: item[0] });
                    resolve(true);
                } else {
                    http.setHeader();
                    http.request().get('banks/details/' + payload).then((res) => {
                        commit(TYPES.mutations.DETAILS_BANK, { bank: res.data });
                        resolve(true);
                    }).catch((error) => {
                        reject(error);
                    })
                }
            })
        },
    }
}
