import { TYPES } from '../types';
import { http } from '@/http'
import { _buildQuery } from '@/utils/Index';

const state = {
    services: [],
    meta: {},
    service: {}
}
export default {
    namespaced: true,
    state: state,

    mutations: {
        [TYPES.mutations.SERVICES_DATA](state, { data }) {
            state.services = data.data;
            state.meta = data.meta
        },

        [TYPES.mutations.DELETE_SERVICE](state, { service }) {
            const index = state.services.indexOf(service);
            state.services.splice(index, 1);
        },

        [TYPES.mutations.DETAILS_SERVICE](state, { service }) {
            state.service = service;
        },
    },
    getters: {
        [TYPES.getters.GET_SERVICES](state) {
            return state.services;
        },
        [TYPES.getters.GET_SERVICES_META](state) {
            return state.meta;
        },
        [TYPES.getters.GET_SERVICE](state) {
            return state.service;
        },
    },
    actions: {
        [TYPES.actions.GET_SERVICES]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get(`services` + _buildQuery(payload)).then((res) => {
                    commit(TYPES.mutations.SERVICES_DATA, { data: res.data });
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.DELETE_SERVICE]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('services/delete', { id: payload.id }).then((res) => {
                    commit(TYPES.mutations.DELETE_SERVICE, { bank: payload });
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.ADD_SERVICE]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('services/create', payload).then((res) => {
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.UPDATE_SERVICE]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('services/update', payload).then((res) => {
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.DETAILS_SERVICE]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('services/details', { id: payload }).then((res) => {
                    commit(TYPES.mutations.DETAILS_SERVICE, { service: res.data });
                    resolve(true)
                }).catch((error) => {
                    reject(error);
                })
            })
        },
    }
}
