import { TYPES } from '../types';
import { http } from '@/http'
import { authenticate, logout, getAuth } from '@/utils/Auth'
import { _buildQuery, parseDateTime } from '@/utils/Index';
const state = {
    user: getAuth(),
    users: [],
    meta: {},
    info: { v: 0, p: 0 }
}
export default {
    namespaced: true,
    state: state,

    mutations: {
        [TYPES.mutations.CONNECTED_USER](state, payload) {
            authenticate(payload);
            state.user = payload;
        },
        [TYPES.mutations.GET_USERS](state, { data }) {
            state.users = data.data;
            state.meta = data.meta
        },
        [TYPES.mutations.UPDATE_USERS](state, { change, model }) {
            let u = change;
            u.created_at = parseDateTime(u.created_at, 'DD-MM-YYYY H:mm');
            const index = state.users.indexOf(model);
            state.users.splice(index, 1, u);
        },
        [TYPES.mutations.ADD_USERS](state, data) {
            let u = data;
            u.created_at = parseDateTime(u.created_at, 'DD-MM-YYYY H:mm');
            state.users.unshift(u);
        },

        [TYPES.mutations.DELETE_USERS](state, { user }) {
            const index = state.users.indexOf(user);
            state.users.splice(index, 1);
        },

        [TYPES.mutations.INFO_USER](state, user) {
            state.info = user
        },
    },
    getters: {
        [TYPES.getters.GET_ACCOUNT](state) {
            return state.user;
        },
        [TYPES.getters.GET_USERS](state) {
            return state.users;
        },
        [TYPES.getters.GET_USERS_META](state) {
            return state.meta;
        },
        [TYPES.getters.INFO_USER](state) {
            return state.info;
        }
    },
    actions: {
        [TYPES.actions.GET_USERS]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get('users' + _buildQuery(payload)).then((res) => {
                    commit(TYPES.mutations.GET_USERS, { data: res.data });
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.ADD_USERS]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('users/create', payload).then((res) => {
                    commit(TYPES.mutations.ADD_USERS, res.data.model);
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.UPDATE_USERS]({ commit }, { form, model }) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('users/update', form).then((res) => {
                    commit(TYPES.mutations.UPDATE_USERS, { change: res.data.model, model: model });
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.DELETE_USERS]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('users/delete', { id: payload.id }).then((res) => {
                    commit(TYPES.mutations.DELETE_USERS, { user: payload });
                    resolve(res)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.AUTHENTICATE_USER]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.request().post('signin', payload).then((res) => {
                    commit(TYPES.mutations.CONNECTED_USER, res.data.user);
                    resolve(res.data.user);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.REGISTER_CUSTOMER]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.request().post('signup', payload).then((res) => {
                    if (res.data.status) {
                        resolve(true);
                    } else resolve(false);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.USER_VERIFICATION]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.request().post('verify-account', payload).then((res) => {
                    if (res.data.status) {
                        resolve(true);
                    } else resolve(false);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.LOGOUT_USER]({ state }) {
            logout();
            state.user = {};
            return true;
        },

        // [TYPES.actions.GET_USER]({ state }) {
        //     return new Promise((resolve, reject) => {
        //         resolve(state.user);
        //     })
        // },

        [TYPES.actions.INFO_USER]({ commit }) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get('users/me').then((res) => {
                    commit(TYPES.mutations.INFO_USER, res.data);
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.RELOAD_APP]({ }) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get('dashobard/app/reload').then((res) => {
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                })
            })
        },
    }
}
