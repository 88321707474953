export const isAuthenticate = () => {
    if (localStorage.getItem('mcp') && localStorage.getItem('mcp') != undefined) {
        return true;
    }
    return false;
}

export const getAuth = () => {
    if (isAuthenticate()) {
        return JSON.parse(localStorage.getItem('mcp'));
    }
    return {};
}

export const isMcp = () => {
    const user = getAuth();
    if (user.profile === 0) {
        return true;
    }
    return false;
}

export const mcpProfile = () => {
    const user = getAuth();
    return user.profile;
}

export const isOperator = () => {
    const user = getAuth();
    if (user.profile === 2) {
        return true;
    }
    return false;
}

export const currentId = () => {
    const user = getAuth();
    if (user.id && user.id != undefined) {
        return user.id;
    }
    return null;
}

export const authenticate = (user) => {
    localStorage.setItem('mcp', JSON.stringify(user));
}

export const logout = () => {
    localStorage.removeItem('mcp');
}

export const coreConfig = () => {
    const user = getAuth();
    if (user.id && user.id != undefined) {
        return user.config
    }
    return null;
}

export const mcpId = () => {
    const user = getAuth();
    if (user.id && user.id != undefined) {
        return user.mcp_id;
    }
    return null;
}