import Vue from 'vue';

import VueLoading from 'vuejs-loading-plugin'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import McpModalCenter from '@/components/McpModalCenter';

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css'

Vue.use(VueToast, {
    position: 'top-right',
    duration: 2000
});

Vue.use(VueLoading, {
    text: ''
});

import tooltip from '@/components/Tooltip';
import Spinner from "@/components/Spinner";

Vue.component('mcp-tooltip', tooltip);
Vue.component('mcp-spinner', Spinner);
Vue.component('mcp-modal-center', McpModalCenter);