export const TYPES = {
    getters: {
        GET_ACCOUNT: 'GET_ACCOUNT',
        GET_CUSTOMERS: 'GET_CUSTOMERS',
        GET_CUSTOMERS_META: 'GET_CUSTOMERS_META',
        GET_USERS: 'GET_USERS',
        GET_USERS_META: 'GET_USERS_META',
        INFO_USER: 'INFO_USER',

        //BANKS
        GET_BANKS: 'GET_BANKS',
        GET_BANKS_META: 'GET_BANKS_META',
        GET_BANK: 'GET_BANK',

        //SERVICES
        GET_SERVICES: 'GET_SERVICES',
        GET_SERVICES_META: 'GET_SERVICES_META',
        GET_SERVICE: 'GET_SERVICE',

        //UTILIS
        GET_PERMISSIONS: 'GET_PERMISSIONS',
        GET_MCP: 'GET_MCP',
        GET_RATING: 'GET_RATING',
        GET_OPERATOR: 'GET_OPERATOR',

        //SUSBSCRIPTION
        GET_SUBSCRIPTIONS: 'GET_SUBSCRIPTIONS',
        GET_SUBSCRIPTIONS_META: 'GET_SUBSCRIPTIONS_META',

        //REFUNDS
        GET_REFUNDS: 'GET_REFUNDS',
        GET_REFUNDS_META: 'GET_REFUNDS_META',

        //SUPPORT
        GET_SUPPORTS: 'GET_SUPPORTS',
        GET_SUPPORTS_META: 'GET_SUPPORTS_META',
        GET_SUPPORT: 'GET_SUPPORT',

        //TRANSACTIONS
        GET_TRANSACTIONS: 'GET_TRANSACTIONS',
        GET_TRANSACTIONS_META: 'GET_TRANSACTIONS_META',

        //DASH ADMIN
        GET_ADMIN_OPERATION: 'GET_ADMIN_OPERATION',
        GET_ADMIN_TOP_DEBIT: 'GET_ADMIN_TOP_DEBIT',
        GET_ADMIN_TOP_CREDIT: 'GET_ADMIN_TOP_CREDIT',

        //DASH CUSTOMER
        GET_CUSTOMER_OPERATION: 'GET_CUSTOMER_OPERATION'
    },
    mutations: {
        //USER
        CONNECTED_USER: 'CONNECTED_USER',
        CUSTOMERS_DATA: 'CUSTOMERS_DATA',
        REST_CUSTOMER_DATA: 'REST_CUSTOMER_DATA',
        GET_USERS: 'GET_USERS',
        ADD_USERS: 'ADD_USERS',
        DELETE_USERS: 'DELETE_USERS',
        UPDATE_USERS: 'UPDATE_USERS',
        INFO_USER: 'INFO_USER',
        REMOVE_MCP_CUSTOMER: 'REMOVE_MCP_CUSTOMER',

        //BANKS
        BANKS_DATA: 'BANKS_DATA',
        DELETE_BANK: 'DELETE_BANK',
        DETAILS_BANK: 'DETAILS_BANK',

        //SERVICES
        SERVICES_DATA: 'SERVICES_DATA',
        DELETE_SERVICE: 'DELETE_SERVICE',
        DETAILS_SERVICE: 'DETAILS_SERVICE',

        //UTILIS
        PERMISSIONS_DATA: 'PERMISSIONS_DATA',
        MCP_DATA: 'MCP_DATA',
        RATING_DATA: 'RATING_DATA',
        OPERATOR_DATA: 'OPERATOR_DATA',

        //SUBSCRIPTIONS
        SUBSCRIPTIONS_DATA: 'SUBSCRIPTIONS_DATA',
        DELETE_SUBSCRIPTION: 'DELETE_SUBSCRIPTION',

        //REFUNDS
        REFUNDS_DATA: 'REFUNDS_DATA',
        DELETE_REFUND: 'DELETE_REFUND',

        //SUPPORTS
        SUPPORTS_DATA: 'SUPPORTS_DATA',
        DELETE_SUPPORT: 'DELETE_SUPPORT',
        DETAILS_SUPPORT: 'DETAILS_SUPPORT',
        PUSH_MESSAGE: 'PUSH_MESSAGE',

        //TRANSACTIONS
        TRANSACTIONS_DATA: 'TRANSACTIONS_DATA',

        //DASH ADMIN
        ADMIN_OPERATION_DATA: 'ADMIN_OPERATION_DATA',
        ADMIN_TOP_DEBIT_DATA: 'ADMIN_TOP_DEBIT_DATA',
        ADMIN_TOP_CREDIT_DATA: 'ADMIN_TOP_CREDIT_DATA',

        //DASH ADMIN
        GET_ADMIN_OPERATION: 'GET_ADMIN_OPERATION',
        GET_ADMIN_TOP_DEBIT: 'GET_ADMIN_TOP_DEBIT',
        GET_ADMIN_TOP_CREDIT: 'GET_ADMIN_TOP_CREDIT',

        //DASH CUSTOMER
        GET_CUSTOMER_OPERATION: 'GET_CUSTOMER_OPERATION'
    },
    actions: {
        // USER
        AUTHENTICATE_USER: 'AUTHENTICATE_USER',
        LOGOUT_USER: 'LOGOUT_USER',
        RESET_ACCOUNT: 'RESET_ACCOUNT',
        REGISTER_CUSTOMER: 'REGISTER_CUSTOMER',
        USER_VERIFICATION: 'USER_VERIFICATION',
        GET_USERS: 'GET_USERS',
        ADD_USERS: 'ADD_USERS',
        UPDATE_USERS: 'UPDATE_USERS',
        DELETE_USERS: 'DELETE_USERS',
        INFO_USER: 'INFO_USER',
        REMOVE_MCP_CUSTOMER: 'REMOVE_MCP_CUSTOMER',
        RELOAD_APP: 'RELOAD_APP',

        //CUSTOMERS
        GET_CUSTOMERS: 'GET_CUSTOMERS',
        ADD_CUSTOMERS: 'ADD_CUSTOMERS',
        EDIT_CUSTOMERS: 'EDIT_CUSTOMERS',

        // BANKS
        GET_BANKS: 'GET_BANKS',
        DELETE_BANK: 'DELETE_BANK',
        ADD_BANK: 'ADD_BANK',
        DETAILS_BANK: 'DETAILS_BANK',
        UPDATE_BANK: 'UPDATE_BANK',

        //SERVICES
        GET_SERVICES: 'GET_SERVICES',
        DELETE_SERVICE: 'DELETE_SERVICE',
        ADD_SERVICE: 'ADD_SERVICE',
        DETAILS_SERVICE: 'DETAILS_SERVICE',
        UPDATE_SERVICE: 'UPDATE_SERVICE',

        //UTILIS
        GET_PERMISSIONS: 'GET_PERMISSIONS',
        GET_MCP: 'GET_MCP',
        GET_RATING: 'GET_RATING',
        GET_OPERATOR: 'GET_OPERATOR',
        UPDATE_PASSWORD: 'UPDATE_PASSWORD',
        UPDATE_AVATAR: 'UPDATE_AVATAR',

        //SUBSCRIPTIONS
        GET_SUBSCRIPTIONS: 'GET_SUBSCRIPTIONS',
        DELETE_SUBSCRIPTION: 'DELETE_SUBSCRIPTION',
        ADD_SUBSCRIPTION: 'ADD_SUBSCRIPTION',
        UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
        GENERATE_API_KEY: 'GENERATE_API_KEY',
        UPDATE_PROFILE: 'UPDATE_PROFILE',

        //REFUNDS
        GET_REFUNDS: 'GET_REFUNDS',
        DELETE_REFUND: 'DELETE_REFUND',
        ADD_REFUND: 'ADD_REFUND',

        //SUPPORTS
        GET_SUPPORTS: 'GET_SUPPORTS',
        DELETE_SUPPORT: 'DELETE_SUPPORT',
        ADD_SUPPORT: 'ADD_SUPPORT',
        DETAILS_SUPPORT: 'DETAILS_SUPPORT',
        SEND_SUPPORT: 'SEND_SUPPORT',
        BEGIN_TREAT: 'BEGIN_TREAT',
        MARK_AS_TREAT: 'MARK_AS_TREAT',

        //TRANSACTIONS
        GET_TRANSACTIONS: 'GET_TRANSACTIONS',
        EXPORT_TRANSACTIONS: 'EXPORT_TRANSACTIONS',

        //DASH ADMIN
        GET_ADMIN_OPERATION: 'GET_ADMIN_OPERATION',
        GET_ADMIN_TOP_DEBIT: 'GET_ADMIN_TOP_DEBIT',
        GET_ADMIN_TOP_CREDIT: 'GET_ADMIN_TOP_CREDIT',
        GET_ADMIN_TRANSACTIONS_GRAPH: 'GET_ADMIN_TRANSACTIONS_GRAPH',
        GET_ADMIN_TRANSACTIONS_GRAPH_CUSTOMER: 'GET_ADMIN_TRANSACTIONS_GRAPH_CUSTOMER',

        //DASH CUSTOMER
        GET_CUSTOMER_OPERATION: 'GET_CUSTOMER_OPERATION'
    },
    modules: {
        USER: 'User',
        CUSTOMERS: 'Customers',
        BANKS: 'Banks',
        SERVICES: 'Services',
        UTILS: 'Utils',
        SUBSCRIPTIONS: 'Subscriptions',
        REFUNDS: 'Refunds',
        SUPPORTS: 'Supports',
        TRANSACTIONS: 'Transactions',
        DASHBOARD: 'Dashboard'
    }
};