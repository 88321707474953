import { TYPES } from '../types';
import { http } from '@/http'
const state = {
    customers: [],
    permissions: [],
    ratings: [],
    operators: []
}
export default {
    namespaced: true,
    state: state,

    mutations: {
        [TYPES.mutations.PERMISSIONS_DATA](state, data) {
            state.permissions = data;
        },

        [TYPES.mutations.MCP_DATA](state, body) {
            state.customers = body.data;
        },

        [TYPES.mutations.RATING_DATA](state, data) {
            state.ratings = data;
        },

        [TYPES.mutations.OPERATOR_DATA](state, data) {
            state.operators = data;
        },
    },
    getters: {
        [TYPES.getters.GET_PERMISSIONS](state) {
            return state.permissions;
        },

        [TYPES.getters.GET_MCP](state) {
            return state.customers;
        },

        [TYPES.getters.GET_RATING](state) {
            return state.ratings;
        },

        [TYPES.getters.GET_OPERATOR](state) {
            return state.operators;
        }
    },
    actions: {
        [TYPES.actions.GET_PERMISSIONS]({ commit, state }) {
            return new Promise((resolve, reject) => {
                if (state.permissions.length === 0) {
                    http.setHeader();
                    http.request().get('permissions').then((res) => {
                        commit(TYPES.mutations.PERMISSIONS_DATA, res.data);
                        resolve(true);
                    }).catch((error) => {
                        reject(error);
                    })
                }
                resolve(true);
            })
        },

        [TYPES.actions.GET_MCP]({ commit, state }) {
            return new Promise((resolve, reject) => {
                if (state.customers.length === 0) {
                    http.setHeader();
                    http.request().get('customers/mcp').then((res) => {
                        commit(TYPES.mutations.MCP_DATA, res.data);
                        resolve(true);
                    }).catch((error) => {
                        reject(error);
                    })
                }
                resolve(true);
            })
        },

        [TYPES.actions.GET_RATING]({ commit, state }) {
            return new Promise((resolve, reject) => {
                if (state.ratings.length === 0) {
                    http.setHeader();
                    http.request().get('permissions/rating').then((res) => {
                        commit(TYPES.mutations.RATING_DATA, res.data);
                        resolve(true);
                    }).catch((error) => {
                        reject(error);
                    })
                }
                resolve(true);
            })
        },


        [TYPES.actions.GET_OPERATOR]({ commit, state }) {
            return new Promise((resolve, reject) => {
                if (state.operators.length === 0) {
                    http.setHeader();
                    http.request().get('banks/operators').then((res) => {
                        commit(TYPES.mutations.OPERATOR_DATA, res.data);
                        resolve(true);
                    }).catch((error) => {
                        reject(error);
                    })
                }
                resolve(true);
            })
        },

        [TYPES.actions.UPDATE_PASSWORD]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('user/update/password', payload).then((res) => {
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.GENERATE_API_KEY]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('user/apikey/generate', payload).then((res) => {
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.UPDATE_PROFILE]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('users/profile/update', payload).then((res) => {
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.UPDATE_AVATAR]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('users/avatar/update', payload).then((res) => {
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                })
            })
        },
    }
}