import { TYPES } from '../types';
import { http } from '@/http'
import { _buildQuery } from '@/utils/Index';

const state = {
    adminOperations: {},
    debitOperations: {},
    creditOpérations: {},
    customerOperations: {}
}
export default {
    namespaced: true,
    state: state,

    mutations: {
        [TYPES.mutations.GET_ADMIN_OPERATION](state, { data }) {
            state.adminOperations = data;
        },
        [TYPES.mutations.GET_ADMIN_TOP_DEBIT](state, { data }) {
            state.debitOperations = data;
        },
        [TYPES.mutations.GET_ADMIN_TOP_CREDIT](state, { data }) {
            state.creditOpérations = data;
        },
        [TYPES.mutations.GET_CUSTOMER_OPERATION](state, { data }) {
            state.customerOperations = data;
        },
    },
    getters: {
        [TYPES.getters.GET_ADMIN_OPERATION](state) {
            return state.adminOperations;
        },
        [TYPES.getters.GET_CUSTOMER_OPERATION](state) {
            return state.customerOperations;
        },
        // [TYPES.getters.GET_ADMIN_TOP_DEBIT](state) {
        //     return state.debitOperations;
        // },
        // [TYPES.getters.GET_ADMIN_TOP_CREDIT](state) {
        //     return state.creditOpérations;
        // },
    },
    actions: {
        [TYPES.actions.GET_ADMIN_OPERATION]({ commit }) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get(`dashobard/admin/operations`).then((res) => {
                    commit(TYPES.mutations.GET_ADMIN_OPERATION, { data: res.data });
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        [TYPES.actions.GET_ADMIN_TOP_DEBIT]({ }) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get(`dashobard/admin/debit-top-five`).then((res) => {
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        [TYPES.actions.GET_ADMIN_TRANSACTIONS_GRAPH]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get(`dashobard/admin/op-charts` + _buildQuery(payload)).then((res) => {
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        [TYPES.actions.GET_ADMIN_TRANSACTIONS_GRAPH_CUSTOMER]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get(`dashobard/customer/op-charts` + _buildQuery(payload)).then((res) => {
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        [TYPES.actions.GET_ADMIN_TOP_CREDIT]({ }) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get(`dashobard/admin/credit-top-five`).then((res) => {
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.GET_CUSTOMER_OPERATION]({ commit }) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get(`dashobard/customer/operations`).then((res) => {
                    commit(TYPES.mutations.GET_CUSTOMER_OPERATION, { data: res.data });
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                })
            })
        },
    }
}
