import moment from 'moment';
export function _buildQuery(params) {
    let string = ''

    for (let key in params) {
        if (params[key] !== "" && params[key] !== null) {
            string += `${key}=${params[key]}&`
        }
    }
    string = '?' + string.substring(0, string.length - 1)
    return string;
}

export function parseDateTime(date, format) {
    if (date != '') {
        return moment(date).format(`${format}`)
    }
    return '';
}