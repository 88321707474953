import axios from 'axios';
import { getAuth, logout } from '@/utils/Auth'
import router from './router';

const api = axios.create({
    baseURL: 'https://auth.mobilecrosspay.com/api/',
    //baseURL: 'http://mcp.local/api/',
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
    },
});

const mcpRequest = {
    setHeader() {
        const token = getAuth().token;
        if (token != null) api.defaults.headers.common["Authorization"] = `Bearer ${token} `;
    },
    intercept(){
        api.interceptors.response.use(function (response) {
            return response;
        }, async (error) =>{
            if (error.response.status === 401) {
                logout();
                router.replace('/signin');
            }else{
                return Promise.reject(error);
            }
        });
    },
    request(){
        return api
    }
}

export const http = mcpRequest

// const api = axios.create({
//     //baseURL: 'https://auth.mobilecrosspay.com/api/',
//     baseURL: 'http://mcp.local/api/',
//     timeout: 30000,
//     headers: {
//         'Content-Type': 'application/json',
//     },
// });
// if (isAuthenticate) {
//     api.defaults.headers.common['Authorization'] = `Bearer ${getAuth().token}`;
// }

//export const http = api;