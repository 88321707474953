import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/Prototype';
import { isAuthenticate } from './utils/Auth'
import '@/utils/GlobalComponents';

Vue.config.productionTip = false
import "bulma/css/bulma.css";
import "@/assets/css/steps.css";
import "@/assets/css/all.min.css";
import "@/assets/layout.scss";
// if (isAuthenticate()) {
//   require('./assets/layout.scss')
// } else {
//   require('./assets/style.scss')
// }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
