import { TYPES } from '../types';
import { http } from '@/http'
import { _buildQuery } from '@/utils/Index';

const state = {
    subscriptions: [],
    meta: {},
}
export default {
    namespaced: true,
    state: state,

    mutations: {
        [TYPES.mutations.SUBSCRIPTIONS_DATA](state, { data }) {
            state.subscriptions = data.data;
            state.meta = data.meta
        },

        [TYPES.mutations.DELETE_SUBSCRIPTION](state, { subscription }) {
            const index = state.subscriptions.indexOf(subscription);
            state.subscriptions.splice(index, 1);
        },
    },
    getters: {
        [TYPES.getters.GET_SUBSCRIPTIONS](state) {
            return state.subscriptions;
        },
        [TYPES.getters.GET_SUBSCRIPTIONS_META](state) {
            return state.meta;
        },
    },
    actions: {
        [TYPES.actions.GET_SUBSCRIPTIONS]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get(`subscriptions` + _buildQuery(payload)).then((res) => {
                    commit(TYPES.mutations.SUBSCRIPTIONS_DATA, { data: res.data });
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.DELETE_SUBSCRIPTION]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('subscriptions/delete', { id: payload.id }).then((res) => {
                    commit(TYPES.mutations.DELETE_SUBSCRIPTION, { subscription: payload });
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.ADD_SUBSCRIPTION]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('subscriptions/create', payload).then((res) => {
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.UPDATE_SUBSCRIPTION]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('subscriptions/update', payload).then((res) => {
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        },
    }
}