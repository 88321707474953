import { TYPES } from '../types';
import { http } from '@/http'
import { _buildQuery } from '@/utils/Index';

const state = {
    refunds: [],
    meta: {},
}
export default {
    namespaced: true,
    state: state,

    mutations: {
        [TYPES.mutations.REFUNDS_DATA](state, { data }) {
            state.refunds = data.data;
            state.meta = data.meta
        },

        [TYPES.mutations.DELETE_REFUND](state, { refund }) {
            const index = state.refunds.indexOf(refund);
            state.refunds.splice(index, 1);
        },
    },
    getters: {
        [TYPES.getters.GET_REFUNDS](state) {
            return state.refunds;
        },
        [TYPES.getters.GET_REFUNDS_META](state) {
            return state.meta;
        }
    },
    actions: {
        [TYPES.actions.GET_REFUNDS]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get(`refunds` + _buildQuery(payload)).then((res) => {
                    commit(TYPES.mutations.REFUNDS_DATA, { data: res.data });
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.DELETE_REFUND]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('refunds/delete', { id: payload.id }).then((res) => {
                    commit(TYPES.mutations.DELETE_REFUND, { refund: payload });
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.ADD_REFUND]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post('refunds/create', payload).then((res) => {
                    resolve(res.data)
                }).catch((error) => {
                    reject(error);
                })
            })
        }
    }
}
