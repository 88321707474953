import { TYPES } from '../types';
import { http } from '@/http'
import { _buildQuery } from '@/utils/Index';

const state = {
    transactions: [],
    meta: {},
}
export default {
    namespaced: true,
    state: state,

    mutations: {
        [TYPES.mutations.TRANSACTIONS_DATA](state, { data }) {
            state.transactions = data.data;
            state.meta = data.meta
        },
    },
    getters: {
        [TYPES.getters.GET_TRANSACTIONS](state) {
            return state.transactions;
        },
        [TYPES.getters.GET_TRANSACTIONS_META](state) {
            return state.meta;
        },
    },
    actions: {
        [TYPES.actions.GET_TRANSACTIONS]({ commit }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().get(`transactions` + _buildQuery(payload)).then((res) => {
                    commit(TYPES.mutations.TRANSACTIONS_DATA, { data: res.data });
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        [TYPES.actions.EXPORT_TRANSACTIONS]({ }, payload) {
            return new Promise((resolve, reject) => {
                http.setHeader();
                http.request().post(`transactions/exports`, payload, {
                    responseType: 'blob'
                }).then((res) => {
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                })
            })
        },
    }
}
